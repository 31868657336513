/* Importing Bootstrap SCSS file. */

$primary: #5223ca;
$secondary: #FF278D;
$yellow: #FFCE31;

$theme-colors: (
  "primary": $primary,
  "seconday": $secondary,
  "yellow": $yellow,
);


@import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";


* {
  font-family: Roboto, sans-serif;
}


@media only screen and (max-width: 575px) {
  .leaflet-control{
    display: none;
  }

  .search-sidebar-container{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: rgba(41, 3, 141, 0.43) !important;
    margin-top: 50px;
    width: 100% !important;
    height: 0 !important;
    top: unset !important;
    bottom: 0 !important;

    &.opened-sidebar{
      height: calc(100% - 50px) !important;
    }

    .rounded-button{
      right: 0;
      background-color: transparent;
      border: 0;
      font-size: 28px;
      padding: 0;
    }
  }
  .opened-sidebar{
    width: 100% !important;
  }

  .no-jobs-modal{
    bottom: 40px !important;
  }

  .cluster-container{
    padding-left: 0px !important;
    bottom: 30px !important;
  }

  .toggle-hide{
    display: none;
  }
  .modal-content{
    padding-bottom: 0px;
    margin-bottom:0px !important;
  }
  .no-job-modal{
    padding-bottom:0px;
    margin-bottom:0px !important;
  }
  .footer{
    justify-content: center !important;
    right: 0 !important;
  }
}

/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

::placeholder {
  font-family: Roboto, sans-serif;;
  font-size: 13px;
  color: #999 !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-width: 8px 8px 0 !important;
}

ngb-typeahead-window.dropdown-menu.show {
  width: unset !important;
}

.min-width-filter {
  min-width: 210px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 13px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Roboto, sans-serif;;
}

h1.font-weight-light,
h2.font-weight-light,
h3.font-weight-light,
h4.font-weight-light,
h5.font-weight-light,
h6.font-weight-light {
  font-family: Roboto, sans-serif;;
}

.pointer {
  cursor: pointer;
}

.ng-select {
  color: #fff !important;

  input {
    padding-left: 8px !important;
    padding-top: 3px;
    color: #fff !important;
    font-weight: bold;
  }

  .ng-select-container {
    border-radius: 20px;
    min-height: 40px;
    background-color: black;
    padding-left: 15px;
    border-style: inset;
    border-color: #000;
    color: #fff;
    font-weight: bold;

    box-shadow: 2px 2px 1px 0 rgb(155 155 155 / 45%) !important;

    &:hover {
      box-shadow: 2px 2px 1px 0 rgb(155 155 155 / 45%) !important;
    }

    .ng-value-container {
      padding-left: 4px !important;
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: #1d1d1d !important;
  }

  &.ng-select-opened > .ng-select-container {
    background: #000;
  }
}

.autocomplete {
  border-radius: 20px;
  min-height: 40px;
  background-color: black;
  padding-left: 15px;
  border-style: inset;
  border-color: #000;
  color: #fff;
  font-weight: bold;
  box-shadow: 2px 2px 1px 0 rgb(155 155 155 / 45%);

  &:disabled {
    background-color: #1d1d1d;
  }

  &:focus {
    color: #fff;
    background-color: #000;
    border-color: #360854;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}


a:not([href]) {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.autocomplete-form-control .ng-clear-wrapper {
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: black;
  cursor: pointer;
  font-size: 20px;
}

.autocomplete-form-control .ng-clear-wrapper:hover {
  color: black
}

.flex-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  background-color: #29172b;
}

.digital-input {
  i {
    position: relative;
    top: 32px;
    margin-left: 10px;
    font-size: 15px;
  }

  input {
    padding-left: 30px;
    background-color: #eeeeee;
    border-radius: 13px;
    border: none;

    &:focus {
      background-color: #eeeeee;
      border: none;
    }
  }
}

.my-cluster-icon > div {
  background-image: url('./assets/img/cluster.png');
  background-size: 35px 45px;
  background-position: center center;
  height: 45px;
  width: 35px;
  padding: 10px 0 0 0;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.modal-content {
  background: transparent;
  border: 0;
  border-radius: 15px;
  margin-bottom: 15px;
}
.modal-p{
  padding: 14px 21px !important;
}
.modal-header{
  font-size: 16px;color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.5rem !important;
  padding: 0px 0px 10px 0px;
  border: 0;
  margin: 0;
  padding: 0;
}
.modal-description{
  font-size: 14px;color:rgba(0, 0, 0, 0.55);
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.modal-bottom .modal-dialog {
  align-items: flex-end;
}

.no-favorites-modal {
  position: fixed;
  z-index: 1062;
  display: none;
  height: auto;
  overflow: hidden;
  outline: 0;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}

.no-jobs-modal {
  position: fixed;
  z-index: 22222;
  display: none;
  max-width: 400px;
  height: 200px;
  overflow: hidden;
  outline: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  top: unset;
}


.cookie-consent-modal{
  z-index: 99999;
  background: rgba(102, 16, 242, 0.07);
}
.cookie-consent-modal-backdrop{
  z-index: 99999 !important;
}

.p-color-7 {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}

input::-webkit-input-placeholder {
  font-size: 16px;
}
